import Backoffice from "@snark/backoffice";
import {userFields} from "./userFields";
import vue from "vue";
import {generateExcelForProfile} from "@/helpers/actions";

const context = {
    profiling: Backoffice.populator('profiling'),
};

let crud = {
    name: "userIndividuaInProgress",
    collection: "user",
    idProperty: "_id",
    section: "Profils",
    labels: {
        "listTitle": "Profilages en cours",
        "singleTitle": "Profilages en cours"
    },
    pagination: 50,
    backTextFilterList: true,
    sort: {back: [{field: "lastname"}, {field: "firstname"}, {field: "profileScore.profilingCount", order: "desc"}, {field: "createdAt", order: "desc"}]},
    backFilter: {
        role: "individual",
        status: "$in:profilingAvailable,profilingInProgress"
    },
    noCreate: true,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_BASE_URL,
                field: "email"
            }
        },
        {
            label: "Ouvrir la prise de RDV",
            cssClass: "Good",
            check: (item) => {
                return !['interviewOpened', 'reportUploaded','feedbackDone'].includes(item.status);
            },
            action: async (item) => {
                await vue.backoffice.crudRequestor.update("user", item._id, {status: "interviewOpened"});
                return {reload: true};
            }
        },
        {
            label: "Exporter Excel",
            cssClass: "Shy",
            action: generateExcelForProfile
        }
    ],
    listColumns: [
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "status",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                "created": "Créé",
                "profilingAvailable": "Profil acheté",
                "profilingInProgress": "Profilage en cours",
                "reportUploaded": "Rapport envoyé",
                "feedbackDone": "Feedback fait",
                "done": "Terminé"
            })
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "group",
            "label": "Client"
        },
        {
            "name": "profileKey",
            "label": "Clé de profilage",
        },
        {
            "name": "requestedProfilers",
            "label": "Profilers invités",
            formatter: (field, item) => item[field.name] ? item[field.name].filter(m => m && m.email.trim().length > 0).length : 0
        },
        {
            "name": "profileScore",
            "label": "Profilages terminés",
            formatter: Backoffice.formatters.subProperty("profilingCount")
        },
        {
            "name": "invoiceNumber",
            "label": "Facture",
        },
        {
            "name": "createdAt",
            "label": "Date d'inscription",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    fields: userFields,
    completeSingle: context.profiling.completeAssociatedList("userId", "_id", "profiling")
};

export default crud;
