import Backoffice from "@snark/backoffice";
import {userFields} from "./userFields";
import {generateExcelForProfile} from "@/helpers/actions";

const context = {
    profiling: Backoffice.populator('profiling'),
};

let crud = {
    name: "userIndividualMeeting",
    collection: "user",
    idProperty: "_id",
    section: "Profils",
    labels: {
        "listTitle": "Profils déterminés",
        "singleTitle": "Profil déterminé"
    },
    pagination: 50,
    backTextFilterList: true,
    sort: {back: [{field: "lastname"}, {field: "firstname"}, {field: "createdAt", order: "desc"}]},
    backFilter: {
        role: "individual",
        status: "$in:reportUploaded,feedbackDone,interviewOpened"
    },
    noCreate: true,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_BASE_URL,
                field: "email"
            }
        },
        {
            label: "Exporter Excel",
            cssClass: "Shy",
            action: generateExcelForProfile
        }
    ],
    listColumns: [
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "status",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                "created": "Créé",
                "profilingAvailable": "Profil acheté",
                "profilingInProgress": "Profilage en cours",
                "reportUploaded": "Rapport envoyé",
                "feedbackDone": "Feedback fait",
                "done": "Terminé"
            })
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "group",
            "label": "Client"
        },
        {
            "name": "invoiceNumber",
            "label": "Facture",
        },
        {
            "name": "createdAt",
            "label": "Date d'inscription",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    fields: userFields,
    completeSingle: context.profiling.completeAssociatedList("userId", "_id", "profiling")
};

export default crud;
