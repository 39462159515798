import Backoffice from "@snark/backoffice";

export const userFields = [
	{
		"name": "_id",
		"label": "Identifiant",
		"readOnly": true,
		"hideOnCreate": true
	},
	{
		"name": "firstname",
		"label": "Prénom"
	},
	{
		"name": "lastname",
		"label": "Nom"
	},
	{
		"name": "email",
		"label": "Email"
	},
	{
		"name": "group",
		"label": "Groupe"
	},
	{
		"name": "phoneNumber",
		"label": "Téléphone"
	},
	{
		"name": "jobTitle",
		"label": "Fonction"
	},
	{
		"name": "password",
		"label": "Nouveau mot de passe",
		hideOnCreate: true,
		writeOnly: true
	},
	{
		"name": "invitationId",
		"label": "Invitation",
		readOnly: true,
		formatter: Backoffice.formatters.linkObjectId("invitation", {
			label: "Voir l'invitation"
		})
	},
	{
		"name": "status",
		"label": "Etat",
		formatter: Backoffice.formatters.mapping({
			"created": "Créé",
			"profilingAvailable": "Profil acheté",
			"profilingInProgress": "Profilage en cours",
			interviewOpened: "Rendez-vous ouvert",
			"reportUploaded": "Rapport envoyé",
			"feedbackDone": "Feedback fait",
			"done": "Terminé"
		}),
		editor: {
			"type": "select",
			"options": [
				{value: "created", label: "Créé"},
				{value: "profilingAvailable", label: "Profil acheté"},
				{value: "profilingInProgress", label: "Profilage en cours"},
				{value: "interviewOpened", label: "Rendez-vous ouvert"},
				{value: "reportUploaded", label: "Rapport envoyé"},
				{value: "feedbackDone", label: "Feedback fait"},
				{value: "done", label: "Terminé"}
			]
		}
	},
	{
		name: "_",
		readOnly: true,
		noLabel: true,
		formatter: () => "<div class='section-title'>Profilage</div>"
	},

	{
		"name": "profileKey",
		"label": "Clé de profilage"
	},
	{
		"name": "profileKey",
		"label": "Lien de profilage",
		readOnly: true,
		formatter: (field, item) => {
			const url = process.env.VUE_APP_FRONT_URL + "/elaboration-profil/" + item.profileKey;
			return '<a target="_blank" href="' + url +'">' + url + "</a>";
		}
	},

	{
		"name": "requestedProfilers",
		"label": "Profileurs invités",
		readOnly: true,
		list: true,
		children: [
			{
				"name": "email",
				"label": "Email du profileur"
			},
			{
				"name": "requestedAt",
				"label": "Date d'invitation",
				"formatter": Backoffice.formatters.date()
			},
			{
				"name": "isProfilingCompleted",
				"label": "Profilage complété",
				"formatter": Backoffice.formatters.boolean()
			},
			{
				"name": "followedUpAt",
				"label": "Date de relance",
				"formatter": Backoffice.formatters.date()
			}
		]
	},

	{
		"name": "profiling",
		"label": "Profilages effectifs",
		readOnly: true,
		list: true,
		children: [
			{
				"name": "profilerEmail",
				"label": "Email du profileur"
			},
			{
				"name": "profilerName",
				"label": "Nom du profileur"
			},
			{
				"name": "status",
				"label": "Etat du profilage",
				formatter: Backoffice.formatters.mapping({
					'started': "Démarré",
					'completed': "Terminé"
				}),
			},
			{
				"name": "updatedAt",
				"label": "Date de dernière action",
				"formatter": Backoffice.formatters.date()
			}
		]
	},

	{
		"name": "profileScore",
		"label": "Score",
		readOnly: true,
		formatter: (field, item) => ({
			is: "ProfileScoreView",
			id: item._id,
			score: item[field.name]
		})
	},
	{
		name: "profilePdfPath",
		label: "Rapport du profil",
		formatter: (field, item) => {
			return item.profilePdfPath ? ('<div style="display:flex;align-items:center;gap:15px;"><i class="icofont-file-document" style="font-size:40px;"></i><a class="Button" href="' + process.env.VUE_APP_API_STATIC_URL + item.profilePdfPath + '" target="_blank"><i class="icofont-download"></i> Voir le rapport</a></div>') : "-";
		},
		editor: {
			type: "file",
			accept: "application/pdf"
		}
	},
	{
		condition: (item) => !!item.feedback,
		name: "feedback",
		label: "Feedback",
		readOnly: true,
		children: [
			{
				"name": "textResponse1",
				label: "Qu'avez-vous pensé de la découverte de votre Profil e-diamant ?",
				formatter: Backoffice.formatters.multiLines()
			},
			{
				"name": "rating1",
				label: "Sur une échelle de 1 à 5, vous êtes-vous reconnu dans votre profil ?",
			},
			{
				name: "_",
				noLabel: true,
				readOnly: true,
				formatter: () => "<div>Cet éclairage sur votre personnalité va-t'il vous permettre :</div>"
			},
			{
				"name": "rating2",
				label: "D'améliorer vos comportements dans des situations génératrices de stress ?",
			},
			{
				"name": "rating3",
				label: "D'améliorer la communication avec des profils différents du votre ?",
			},
			{
				"name": "rating4",
				label: "De gagner en efficacité professionnelle ?",
			},
			{
				"name": "textResponse2",
				label: "Au vu des résultats de votre profil et de ce que vous avez découvert dans ce document, quels sont les 3 principaux objectifs / engagements de progrès que vous souhaitez prendre ?",
				formatter: Backoffice.formatters.multiLines()
			},
			{
				"name": "textResponse3",
				label: "Qu'attendez vous de l'entretien de restitution avec le coach consultant ?",
				formatter: Backoffice.formatters.multiLines()
			}
		]
	},

	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		name: "_",
		formatter: () => "<div class='section-title'>Paiement</div>"
	},

	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "invoiceNumber",
		"label": "Numéro de facture",
		readOnly: true
	},

	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "paymentReference",
		"label": "Référence de paiement",
		readOnly: true
	},

	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "latestPaymentStartedAt",
		"label": "Dernier paiement commencé le",
		"formatter": Backoffice.formatters.date(),
		readOnly: true
	},
	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "latestPaymentResultAt",
		"label": "Résultat du paiement à",
		"formatter": Backoffice.formatters.date(),
		readOnly: true
	},
	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "paymentResult",
		"label": "Détail",
		"formatter": (field, item) => "<pre>" + JSON.stringify(item[field.name], null, 4) + "</pre>",
		readOnly: true
	},
	{
		condition: (item) => item.paymentStartedCount && item.paymentStartedCount > 0,
		"name": "paymentStartedCount",
		"label": "Nombre de paiements commencés",
		readOnly: true
	},

	{
		"name": "state",
		"label": "Désactivé ?",
		editor: {
			"type": "select",
			"options": [
				{value: null, label: "actif"},
				{value: "deleted", label: "désactivé"}
			]
		},
		hideOnCreate: true,
	},

	{
		"name": "createdAt",
		"label": "Créé le",
		"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
		"readOnly": true,
		"hideOnCreate": true
	},
	{
		"name": "updatedAt",
		"label": "Modifié le",
		"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
		"readOnly": true,
		"hideOnCreate": true
	},
];